import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from './App';
import About from './pages/About';
import Found from './pages/Found';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App/>} exact />
      <Route path="/about" element={<About />} />
      <Route path="/found" element={<Found />} />
    </Routes>
  </BrowserRouter>
);
