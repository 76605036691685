import React from "react"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"

class Layout extends React.Component {
  render(){
    return (
      <div className="body-container">
        <Header />
        <main className="container">{this.props.children}</main>
        <Footer />
      </div>
    )
  }
}
export default Layout;