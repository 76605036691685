import React from "react";
import { Link } from "react-router-dom";

class Header extends React.Component {
  render() {
    return(
      <header className="header-container">
        <Link to="/" className="h3">Leo Morrissey</Link>
        <Link to="/about" className="h3">About</Link>
      </header>
    );
  }
}
export default Header;