import React from "react";

class Footer extends React.Component {
  render() {
    return(
      <footer>
        <p>&copy; Leo Morrissey {new Date().getFullYear()}</p>
      </footer>
    );
  }
}

export default Footer;