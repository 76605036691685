import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Layout from './components/Layout';

export default function App() {
  return (
    <>
    <Layout>
        <h1>Projects</h1>
        <Link to="/found" className="h2">FOUND</Link>
    </Layout>
    </>
  );
}