import React from 'react';
import Layout from '../components/Layout';

class About extends React.Component {
  render() {
    return (
      <>
        <Layout>
            <h1>About</h1>
            <div class="collapse">
                <p class="h5">Studio: Jersey City, New Jersey, USA</p>
                <p class="h5">Lives/works: Sea Isle City, New Jersey USA</p>
            </div>
            <div>
                <p>Leo Morrissey is an artist whose work deals with the personal notation of time in his everyday life. He works from a conceptual perspective dealing with seriality and recording time and place; his interest is in the commonality and history of objects, not materials. Morrissey explores the depth of meaning that emerges with the repeated use of images over time, he is drawn to the complexity of the simple, the universality and mystery of image as language. An integral part of his practice is site /place specific, projects have taken place and works have been created while traveling in Europe, Asia, Africa, Central America, South America and North America.</p>
                <p>Morrissey received his MFA from Rutgers University. He has been a recipient of a Pollack-Krasner Foundation Grant and a Hauberg Fellowship from Pilchuck Glass School. His artwork has been included in exhibitions at the Taiwan Museum of Art, Monash Gallery in Australia, Glassmusset Ebeltoft Denmark, the Musee du Pays et Val De Charmey, Switzerland, Museum Of Modern Art, New York, NY and the Galleria del Carbone, Ferrara Italy. His work is also included in the PIEROGI Flatfiles in Brooklyn, NY., NY.</p>
            </div>
        </Layout>
      </>
    );
  }
}

export default About;