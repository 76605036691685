import React from 'react';
import { useState } from 'react';
import Layout from '../components/Layout';

const Found = () =>{
    const imageLibrary = [
        { imageFront: "/images/1-front.jpg", imageBack: "/images/1-back.jpg", size:"six-four", key: "image0" },
        { imageFront: "/images/2-front.jpg", imageBack: "/images/2-back.jpg", size:"five-four", key: "image1" },
        { imageFront: "/images/3-front.jpg", imageBack: "/images/3-back.jpg", size:"five-four", key: "image2" },
        { imageFront: "/images/4-front.jpg", imageBack: "/images/4-back.jpg", size:"six-four", key: "image3"}
    ];

    function FlipImage({ data, renderEmpty }) {
        const [imageToShow, setImageToShow] = useState("");
        const [lightboxDisplay, setLightBoxDisplay] = useState(false);

        const handleToggle = (index) => {
            const sample = document.getElementById(`image${index}`);
            sample.classList.toggle("active");
        };

        //function to show a specific image in the lightbox, amd make lightbox visible
        const showImage = (image) => {
            setImageToShow(image);
            setLightBoxDisplay(true);
        };

        //hide lightbox
        const hideLightBox = () => {
            setLightBoxDisplay(false);
        };

        const imageCards = imageLibrary.map((item, index) => (
            <div className={`flip box`} key={item.key} id={item.key}>
                <div className="front">
                    <img className={item.size} src={item.imageFront} onClick={() => showImage(item.imageFront)} loading="lazy" alt="" />
                </div>
                <div className="back">
                    <img className={item.size} src={item.imageBack} onClick={() => showImage(item.imageBack)} loading="lazy" alt="" />
                </div>
                <button onClick={()=>handleToggle(index)} style={{width: '32px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048">
                        <path
                        d="M1024 768q53 0 99 20t82 55 55 81 20 100q0 53-20 99t-55 82-81 55-100 20q-53 0-99-20t-82-55-55-81-20-100q0-53 20-99t55-82 81-55 100-20zm0 384q27 0 50-10t40-27 28-41 10-50q0-27-10-50t-27-40-41-28-50-10q-27 0-50 10t-40 27-28 41-10 50q0 27 10 50t27 40 41 28 50 10zm1024-128q0 140-37 272t-105 248-167 213-221 163h274v128h-512v-512h128v297q117-55 211-140t162-190 103-228 36-251q0-123-32-237t-90-214-141-182-181-140-214-91-238-32q-123 0-237 32t-214 90-182 141-140 181-91 214-32 238H0q0-141 36-272t103-245 160-207 208-160T751 37t273-37q141 0 272 36t245 103 207 160 160 208 103 245 37 272z"
                        />
                    </svg>
                    <span className="visually-hidden">Flip Card</span>
                </button>
            </div>
        ))

        return (
            <div className='parent'>
              {imageCards}
              
              {
                lightboxDisplay ? 
                <div id="lightbox" onClick={hideLightBox}>
                  <img id="lightbox-img" src={imageToShow} alt="" />
                </div>
               : ""
              }
            </div>
        );
    }

    return (
      <>
        <Layout>
          <h1>FOUND</h1>
          <p className="h4">2007-current</p>
        <div>
            <p>The FOUND prints are a body of work started around 2007 and continues...</p>
            <p>This work deals with the personal notation of time in my everyday life and is conceptually rooted in seriality. An integral part of my creative practice is site oriented, place & time are important factors, projects including FOUND have taken place while traveling in Europe, Asia, Africa, Central America, South America and North America.</p>
            <p>The FOUND prints are created from things found while walking. The object is inked on a conventional stamp pad and printed on a blank postcard. Below the printed object the date and city where found is written, under this the word FOUND printed with a rubber stamp. Each card is addressed and mailed. Works are mailed to the artist, selected galleries, individuals and to exhibitions. At the end of each year prints are filed in a photo/postcard storage box in chronological order.</p>
            <p>Redfox Press, Achill Island Ireland, has published a book of FOUND works that were mailed to Redfox Press.</p>
        </div>
        <div className="collapse">
            <p className="h5">"FOUND"</p>
            <p>Artist Book</p>
            <p>Redfox Press</p>
            <p>10 x 14.50 cm limited edition book.</p>
            <a className="external" href="http://redfoxpress.com/AB-morrissey.html" target="_blank" rel="noopener noreferrer">Purchase on Redfox Press</a>
        </div>
            <FlipImage
                data={imageLibrary}
                renderEmpty={<p>This list is empty</p>}
            />
        </Layout>
      </>
    );
}

export default Found;